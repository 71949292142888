import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Container from '~components/Container';

import Link from '~components/Link';
import { STORE_LISTING_URLS } from '~src/utils/app-helper';
import PlaystoreImg from '~images/icons/playstore_download.svg';
import AppstoreImg from '~images/icons/appstore_download.svg';
import PhonesImage from './images/phones-cropped.png';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#F6F6F6'
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '40px',
    marginBottom: '70px',
    gap: '23px',
    width: '100%'
  },
  heading: {
    fontWeight: '600',
    fontSize: '48px',
    lineHeight: '54px',
    margin: 'auto',
    maxWidth: '600px',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      fontSize: '42px',
      lineHeight: '50px',
      padding: '0px 10px'
    }
  },
  description: {
    color: '#000',
    textAlign: 'center',
    fontFamily: 'Inter',
    fontSize: '24px',
    fontWeight: 400,
    lineHeight: '140%',
    margin: 'auto',
    maxWidth: 750
  },
  storeListing: {
    display: 'flex',
    flexDirection: 'row',
    gap: 23,
    margin: 'auto'
  },
  image: {
    width: '100%'
  }
}));

const WeeklyTorahPortionAccessFromAnywhereSection = () => {
  const classes = useStyles();

  return (
    <Container center className={classes.root}>
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
      >
        <Grid item>
          <div className={classes.headerContainer}>
            <Typography className={classes.heading}>Weekly Torah Portion App</Typography>
            <h4 className={classes.description}>
              Take Parsha podcasts and videos on the go by downloading the Aleph Beta App
            </h4>
            <div className={classes.storeListing}>
              <Link href={STORE_LISTING_URLS.androidLink} target="_blank">
                <img src={PlaystoreImg} alt="google play store" />
              </Link>
              <Link href={STORE_LISTING_URLS.iOSLink} target="_blank">
                <img src={AppstoreImg} alt="apple appstore" />
              </Link>
            </div>
          </div>
        </Grid>
        <Grid item container direction="column" alignItems="center" xs={12} spacing={2}>
          <div>
            <img src={PhonesImage} alt="app" className={classes.image} />
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};

export default WeeklyTorahPortionAccessFromAnywhereSection;
