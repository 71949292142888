import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Box, Grid } from '@material-ui/core';
import RichText from '~components/RichText';
import Container from '~components/Container';
import ResourceCard from './ResourceCard';
import FeaturedResourceCard from './FeaturedResourceCard';

const useStyles = makeStyles(theme => ({
  subtitle: {
    maxWidth: '1500px',
    margin: '-30px auto 40px',
    '& > *': {
      fontSize: '28px',
      lineHeight: '44px',
      fontFamily: 'Inter',
      fontWeight: 500,
      textAlign: 'center',
      marginBlockStart: 0,
      marginBlockEnd: 0,
      color: '#212121',
      [theme.breakpoints.down('sm')]: {
        fontSize: '24px',
        lineHeight: '34px'
      }
    }
  }
}));

const Resources = ({ title, subtitle, children }) => {
  const classes = useStyles();
  return (
    <Box component={Container} center size={50} pt={4} pb={4} id="AdditionalResources">
      {children &&
        children
          .filter(child => child.props.is_featured)
          .map((child, index) => (
            <Grid
              container
              key={`resource-key-${title}-${index}` || `resource-key-Additional Resources-${index}`}
            >
              <FeaturedResourceCard resource={child.props} />
            </Grid>
          ))}

      {title && <RichText html={title} mode="resourcesSectionTitleBold" />}
      {subtitle && <RichText html={subtitle} externalClassName={classes.subtitle} />}

      <Grid container direction="row" justifyContent="center" alignItems="flex-start" spacing={4}>
        {children &&
          children
            .filter(child => !child.props.is_featured)
            .map((child, index) => (
              <Grid
                item
                key={
                  `resource-key-${title}-${index}` || `resource-key-Additional Resources-${index}`
                }
              >
                <ResourceCard resource={child.props} />
              </Grid>
            ))}
      </Grid>
    </Box>
  );
};

Resources.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  children: PropTypes.node.isRequired
};
Resources.defaultProps = {
  title: 'Additional Resources',
  subtitle: ''
};

export default Resources;
