/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import parse, { domToReact } from 'html-react-parser';
import he from 'he';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';

import Link from '~components/Link';

const useStyles = makeStyles(theme => ({
  heroOverlineHtml: {
    '& > *': {
      ...theme.typography.subtitle1,
      color: props => props.textColor,
      '& > a': {
        color: props => props.textColor,
        textDecoration: 'underline'
      }
    }
  },
  heroUnderlineHtml: {
    '& > *': {
      color: props => props.textColor,
      ...theme.typography.h2,
      fontSize: '1rem',
      lineHeight: '1.75',
      fontWeight: '600'
    }
  },
  playlistsSectionTitle: {
    '& > *': { ...theme.typography.h5, margin: 0, textAlign: 'left' }
  },
  nonFeaturedPostHeading: {
    '& > *': { ...theme.typography.h5, margin: 0 }
  },
  nonFeaturedPostOverline: {
    '& > *': {
      ...theme.typography.caption,
      color: theme.palette.primary.main,
      textTransform: 'uppercase',
      fontWeight: 800
    }
  },
  resourcesSectionTitle: {
    '& > *': {
      ...theme.typography.h3,
      margin: 0,
      textAlign: 'center',
      paddingBottom: '100px',
      paddingTop: '100px'
    }
  },
  resourcesSectionTitleBold: {
    '& > *': {
      ...theme.typography.h3,
      margin: 0,
      textAlign: 'center',
      paddingBottom: '30px',
      paddingTop: '100px',
      fontWeight: '600'
    }
  },
  textBlockSectionTitle: {
    '& > *': {
      ...theme.typography.h4,
      ...theme.typography.gutterBottom,
      margin: 0,
      textAlign: 'center'
    }
  },
  textBlockColsSectionTitle: {
    '& > *': {
      ...theme.typography.h4,
      ...theme.typography.gutterBottom,
      margin: 0,
      textAlign: 'center'
    }
  },
  holidayTextBlockSectionTitle: {
    '& > *': {
      fontWeight: 'bold',
      fontSize: '56px',
      lineHeight: '66px',
      ...theme.typography.gutterBottom,
      margin: 0,
      textAlign: 'center',
      marginBottom: '60px',
      [theme.breakpoints.only('xs')]: {
        fontSize: '40px',
        lineHeight: '46px',
        marginBottom: '30px'
      }
    }
  },
  holidayHeroOverlineHeading: {
    '& > *': {
      color: '#2e72ac',
      fontSize: '16px',
      fontWeight: 'normal',
      opacity: '0.87',
      lineHeight: '19px',
      letterSpacing: '0.08333em',
      margin: 0,
      marginBlockStart: '139px',
      marginBottom: '20px',
      '@media only screen and (max-width: 1200px)': {
        marginTop: '70px'
      }
    }
  },
  blueText: {
    marginBlockStart: '135px',
    color: '#2e72ac',
    fontSize: '24px',
    '& > *': {
      fontSize: '24px',
      color: '#2e72ac'
    },
    [theme.breakpoints.only('xs')]: {
      marginBlockStart: '75px'
    }
  },
  verticalHolidayHeroOverlineHeading: {
    '& > *': {
      color: '#2e72ac',
      fontSize: '16px',
      fontWeight: 'normal',
      opacity: '0.87',
      lineHeight: '19px',
      letterSpacing: '0.08333em',
      margin: 0,
      marginBottom: '20px',
      marginBlockStart: 0,
      '@media only screen and (max-width: 1200px)': {
        marginTop: '70px'
      }
    }
  },
  holidayHeroTitle: {
    '& > *': {
      fontWeight: 'bold',
      fontSize: '64px',
      lineHeight: '74px',
      marginTop: 0,
      textAlign: 'left',
      marginBlockStart: 0,
      marginBlockEnd: '30px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '46px',
        lineHeight: '52px'
      },
      '@media only screen and (max-width: 322px)': {
        fontSize: '38px'
      }
    },
    '& > .h1': {
      fontSize: '72px'
    },
    '& > .h2': {
      fontSize: '64px'
    },
    '& > .h3': {
      fontSize: '56px'
    },
    '& > .h4': {
      fontSize: '48px'
    },
    '& > .h5': {
      fontSize: '40px'
    },
    '& > .h6': {
      fontSize: '32px'
    }
  },
  holidayHeroSubtitle: {
    '& > *': {
      fontSize: '23px',
      lineHeight: '36px',
      marginTop: 0,
      paddingRight: '66px',
      textAlign: 'left',
      marginBlockStart: 0,
      marginBlockEnd: 0,
      [theme.breakpoints.down('md')]: {
        paddingRight: 'unset',
        marginButtom: '35px'
      }
    }
  },
  faqHeroTitle: {
    '& > *': {
      fontSize: '56px',
      lineHeight: '66px',
      fontWeight: 'bold',
      marginTop: '84px',
      marginBottom: '34px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '42px',
        lineHeight: '46px'
      },
      [theme.breakpoints.down('md')]: {
        marginBottom: '35px'
      }
    }
  },
  faqHeroSubtitle: {
    '& > *': {
      fontSize: '20px',
      lineHeight: '30px',
      marginTop: 0,
      textAlign: 'center',
      marginBlockStart: 0,
      marginBottom: '70px',
      [theme.breakpoints.down('md')]: {
        marginBottom: '35px'
      }
    }
  },
  faqTopicTitle: {
    '& > *': {
      fontSize: '24px',
      lineHeight: '30px',
      fontWeight: '500',
      marginTop: 0,
      textAlign: 'left',
      marginBlockStart: 0,
      marginBlockEnd: 0
    }
  },
  faqTopicContent: {
    '& > *': {
      fontSize: '20px',
      lineHeight: '30px',
      marginBlockStart: 0
    }
  },
  faqAudioPlayerText: {
    '& > *': {
      fontSize: '18px',
      lineHeight: '30px',
      fontWeight: 'normal',
      marginBlockStart: '31px',
      marginBlockEnd: '0px'
    }
  },
  faqNavigationLink: {
    '& > *': {
      fontSize: '20px',
      lineHeight: '30px',
      fontWeight: '500',
      marginTop: 0,
      textAlign: 'left',
      color: '#000000',
      opacity: 0.5
    }
  },
  teamHeroTitle: {
    '& > *': {
      fontSize: '64px',
      lineHeight: '75px',
      fontWeight: 'bold',
      textAlign: 'left',
      marginBlockStart: 0,
      marginBlockEnd: 0,
      color: '#fff'
    }
  },
  teamHeroSubtitle: {
    '& > *': {
      fontSize: '20px',
      lineHeight: '30px',
      fontWeight: 'normal',
      textAlign: 'left',
      marginBlockStart: 0,
      marginBlockEnd: 0,
      color: '#fff',
      opacity: '0.87'
    }
  },
  teamPageSectionTitle: {
    '& > *': {
      fontSize: '36px',
      lineHeight: '42px',
      fontWeight: 'bold',
      textAlign: 'left',
      marginBlockStart: 0,
      marginBlockEnd: '48px'
    }
  },
  teamPageMemberName: {
    '& > *': {
      fontSize: '24px',
      lineHeight: '28px',
      fontWeight: 'bold',
      textAlign: 'center',
      marginBlockStart: '16px',
      marginBlockEnd: '0px',
      opacity: '0.87'
    }
  },
  teamPageMemberPosition: {
    '& > *': {
      fontSize: '18px',
      lineHeight: '21px',
      fontWeight: 'normal',
      textAlign: 'center',
      marginBlockStart: '8px',
      marginBlockEnd: '8px',
      color: '#458FCE',
      opacity: '0.87'
    }
  },
  teamPageFounderText: {
    '& > *': {
      fontSize: '20px',
      lineHeight: '30px',
      fontWeight: 'normal',
      marginBlockStart: '0px',
      opacity: '0.87'
    }
  },
  teamPageFounderPublicationsTitle: {
    '& > *': {
      fontSize: '24px',
      lineHeight: '28px',
      fontWeight: '500',
      marginBlockStart: '45px',
      marginBlockEnd: '0px',
      opacity: '0.87'
    }
  },
  teamPageJobSectionTitle: {
    '& > *': {
      fontWeight: 'bold',
      fontSize: '56px',
      lineHeight: '66px',
      marginBlockStart: '0px',
      marginBottom: '53px',
      opacity: '0.87',
      [theme.breakpoints.only('xs')]: {
        fontSize: '44px',
        lineHeight: '50px'
      }
    }
  },
  aboutHeroText: {
    '& > *': {
      fontSize: '24px',
      lineHeight: '34px',
      textAlign: 'center',
      fontWeight: '400',
      marginBlockStart: '0px',
      marginBottom: '0px',
      opacity: '0.87'
    }
  },

  aboutFaqTitle: {
    '& > *': {
      fontSize: '20px',
      lineHeight: '30px',
      fontWeight: '700',
      marginBlockStart: 0,
      marginBlockEnd: 0
    }
  },
  aboutVideosSectionTitle: {
    '& > *': {
      fontWeight: 'bold',
      fontSize: '56px',
      lineHeight: '66px',
      marginBlockStart: '0px',
      marginBlockEnd: 0,
      opacity: '0.87',
      [theme.breakpoints.only('xs')]: {
        fontSize: '44px',
        lineHeight: '50px'
      }
    }
  },
  aboutVideosFootnote: {
    '& > *': {
      fontSize: '20px',
      lineHeight: '30px',
      marginBlockStart: '0px',
      textAlign: 'center',
      marginBlockEnd: 0,
      opacity: '0.87'
    }
  },
  aboutCtaLinkSubtitle: {
    '& > *': {
      fontSize: '18px',
      lineHeight: '30px',
      fontWeight: 'normal',
      marginBlockStart: '0px',
      marginBlockEnd: '0px',
      opacity: '0.6'
    }
  },
  aboutPageSlideTitle: {
    '& > *': {
      fontSize: '24px',
      lineHeight: '28px',
      fontWeight: 'bold',
      marginBlockStart: '45px',
      marginBlockEnd: '0px',
      opacity: '0.87'
    }
  },
  abLabsLetter: {
    '& > *': {
      margin: 0,
      fontSize: '16px',
      lineHeight: '26px'
    },
    '& > p:empty': {
      height: '26px'
    },
    '& > h1': {
      margin: 0,
      fontSize: '25px',
      lineHeight: '26px'
    },
    '& > h2': {
      margin: 0,
      fontSize: '22px',
      lineHeight: '26px'
    },
    '& > h3': {
      margin: 0,
      fontSize: '20px',
      lineHeight: '26px'
    },
    '& > h4': {
      margin: 0,
      fontSize: '18px',
      lineHeight: '26px'
    }
  },
  videoListTitle: {
    '& > *': {
      fontWeight: 500,
      fontSize: '36px',
      lineHeight: '42px',
      margin: 0,
      marginBlockStart: 0,
      marginBlockEnd: 0
    }
  },
  videoListSubtitle: {
    '& > *': {
      fontSize: '16px',
      lineHeight: '19px',
      opacity: 0.9,
      marginBlockEnd: '38px'
    }
  },
  premiumHeroGuides: {
    '& > *': {
      fontSize: '18px',
      lineHeight: '28px',
      marginBlockStart: 0,
      marginBlockEnd: 0,
      letterSpacing: '0.00938em'
    }
  },
  curationHeroTitle: {
    '& > *': {
      fontSize: '36px',
      lineHeight: '42px',
      fontWeight: 'bold',
      marginBlockStart: 0,
      marginBlockEnd: '26px',
      letterSpacing: '0.00938em',
      color: 'inherit'
    }
  },
  curationHeroSubtitle: {
    '& > *': {
      fontSize: '16px',
      lineHeight: '24px',
      fontStyle: 'normal',
      fontWeight: 'normal',
      marginBlockStart: 0,
      marginBlockEnd: 0,
      color: 'inherit'
    }
  },
  curationVideoDescription: {
    '& > *': {
      fontSize: '12px',
      wordBreak: 'break-word',
      lineHeight: '16px',
      marginBlockEnd: '15px',
      marginBlockStart: '6px',
      color: 'inherit',
      opacity: 0.7
    }
  },
  sponsorshipProjectContent: {
    '& > *': {
      fontSize: '18px',
      lineHeight: '28px',
      fontStyle: 'normal',
      fontWeight: 400,
      marginBlockStart: 0,
      marginBlockEnd: 0,
      color: '#1D245E',
      [theme.breakpoints.down('sm')]: {
        fontSize: '20px',
        lineHeight: '28px'
      }
    }
  },
  podcastSubtitle: {
    '& > *': {
      ...theme.typography.h6,
      textAlign: 'center',
      fontWeight: 400,
      marginBlockStart: '0.5rem',
      marginBlockEnd: '0.5rem'
    }
  },
  paragraph: {
    '& > p': {
      ...theme.typography.subtitle1,
      marginBlockStart: 0,
      marginBlockEnd: '0.35rem',
      color: props => props.textColor
    }
  },
  content: {
    textAlign: props => props.textAlign,
    ...theme.typography.subtitle1,
    color: props => props.textColor || 'inherit',
    '&': {
      width: '100%'
    },
    // Force disable full align on any headers on any nesting level
    '& h1, & h2, & h3, & h4, & h5, & h6': {
      textAlign: 'left !important'
    },
    '& > h1': {
      ...theme.typography.h2,
      fontSize: '3.2rem',
      textAlign: props => (props.textAlign ? props.textAlign : 'center'),
      fontWeight: '400',
      lineHeight: '1.04',
      marginBlockEnd: '-15px'
    },
    '& > h2': {
      fontSize: '2.5rem',
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: '400',
      lineHeight: '1.04',
      marginBlockStart: '1em',
      marginBlockEnd: '0.5em',
      letterSpacing: '0em'
    },
    '& > h3': {
      fontSize: '2rem',
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: '400',
      lineHeight: '1.04',
      marginBlockStart: '0.7em',
      marginBlockEnd: '0.4em',
      letterSpacing: '0em'
    },
    '& > h4': {
      fontSize: '1.7rem',
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: '400',
      lineHeight: '1.04',
      marginBlockStart: '0.7em',
      marginBlockEnd: '0.35em',
      letterSpacing: '0em'
    },
    '& > h5': {
      ...theme.typography.h5,
      marginBlockStart: '0.6em',
      marginBlockEnd: '0.3em'
    },
    '& > h6': {
      ...theme.typography.h6,
      marginBlockStart: '0.6em',
      marginBlockEnd: '0.3em'
    },
    '& > p': {
      ...theme.typography.subtitle1
    },
    '& > ol, ul': {
      ...theme.typography.subtitle1
    },
    '& img': {
      maxWidth: '100%',
      display: 'flex',
      margin: '0 auto'
    },
    '& > p > .align-left': {
      textAlign: 'left',
      display: 'block'
    },
    '& > p > .align-right': {
      textAlign: 'right',
      display: 'block'
    },
    '& > pre': {
      whiteSpace: 'pre-wrap',
      wordWrap: 'break-word'
    },
    '& > pre > iframe': {
      [theme.breakpoints.only('xs')]: {
        maxWidth: '100%',
        maxHeight: '100%'
      },
      margin: '0 auto',
      display: 'block'
    },
    '& > p > .align-center': {
      textAlign: 'center',
      display: 'block'
    },

    '& > * > .h1': {
      ...theme.typography.h2,
      fontSize: '3.2rem',
      textAlign: 'center',
      fontWeight: '400',
      lineHeight: '1.04',
      marginBlockEnd: '-15px'
    },
    '& > * > .h2': {
      fontSize: '2.5rem',
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: '400',
      lineHeight: '1.04',
      marginBlockStart: '1em',
      marginBlockEnd: '0.5em',
      letterSpacing: '0em'
    },
    '& > * > .h3': {
      fontSize: '2rem',
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: '400',
      lineHeight: '1.04',
      marginBlockStart: '0.7em',
      marginBlockEnd: '0.4em',
      letterSpacing: '0em'
    },
    '& > * > .h4': {
      fontSize: '1.7rem',
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: '400',
      lineHeight: '1.04',
      marginBlockStart: '0.7em',
      marginBlockEnd: '0.35em',
      letterSpacing: '0em'
    },
    '& > * > .h5': {
      ...theme.typography.h5,
      marginBlockStart: '0.6em',
      marginBlockEnd: '0.3em'
    },
    '& > * > .h6': {
      ...theme.typography.h6,
      marginBlockStart: '0.6em',
      marginBlockEnd: '0.3em'
    },
    '& > * > .p': {
      ...theme.typography.subtitle1,
      marginBlockStart: '0.3em',
      marginBlockEnd: '0.2em'
    },
    '& > * > * > .h1': {
      ...theme.typography.h2,
      fontSize: '3.2rem',
      textAlign: 'center',
      fontWeight: '400',
      lineHeight: '1.04',
      marginBlockEnd: '-15px'
    },
    '& > * > * > .h2': {
      fontSize: '2.5rem',
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: '400',
      lineHeight: '1.04',
      marginBlockStart: '1em',
      marginBlockEnd: '0.5em',
      letterSpacing: '0em'
    },
    '& > * > * > .h3': {
      fontSize: '2rem',
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: '400',
      lineHeight: '1.04',
      marginBlockStart: '0.7em',
      marginBlockEnd: '0.4em',
      letterSpacing: '0em'
    },
    '& > * > * > .h4': {
      fontSize: '1.7rem',
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: '400',
      lineHeight: '1.04',
      marginBlockStart: '0.7em',
      marginBlockEnd: '0.35em',
      letterSpacing: '0em'
    },
    '& > * > * > .h5': {
      ...theme.typography.h5,
      marginBlockStart: '0.6em',
      marginBlockEnd: '0.3em'
    },
    '& > * > * > .h6': {
      ...theme.typography.h6,
      marginBlockStart: '0.6em',
      marginBlockEnd: '0.3em'
    },
    '& > * > * > .p': {
      ...theme.typography.subtitle1,
      marginBlockStart: '0.3em',
      marginBlockEnd: '0.2em'
    },
    '& > * > strong > .p': {
      ...theme.typography.subtitle1,
      marginBlockStart: '0.3em',
      marginBlockEnd: '0.2em',
      fontWeight: '700'
    },
    '& > * > iframe': {
      maxWidth: '100%'
    }
  }
}));

const parserOptions = {
  replace: ({ name, attribs, children }) => {
    switch (name) {
      case 'a':
        return (
          <Link {...attribs} to={attribs.href}>
            {domToReact(children)}
          </Link>
        );
      default:
        return null;
    }
  }
};

const RichText = ({ id, html, textAlign, textColor, mode, externalClassName, verticalSpacing }) => {
  const classes = useStyles({ textAlign, textColor });
  const className = mode && classes[mode] ? classes[mode] : classes.content;

  const decodedHtml = he.decode(html || '');
  return (
    <Box id={id} my={verticalSpacing} maxWidth="100%" className={externalClassName || className}>
      {parse(decodedHtml, parserOptions)}
    </Box>
  );
};

RichText.propTypes = {
  id: PropTypes.string,
  html: PropTypes.string.isRequired,
  textAlign: PropTypes.string,
  textColor: PropTypes.string,
  mode: PropTypes.string,
  externalClassName: PropTypes.string,
  verticalSpacing: PropTypes.number
};

RichText.defaultProps = {
  id: '',
  textAlign: 'left',
  textColor: 'inherit',
  mode: undefined,
  externalClassName: null,
  verticalSpacing: 2
};

export default RichText;
