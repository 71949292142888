import React, { useState } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Container from '~components/Container';

import { SECTION_CONTENT } from './WeeklyTorahPortionParshaStudy';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#F6F6F6',
    paddingBottom: '100px'
  },
  title: {
    fontWeight: '600',
    fontSize: '48px',
    lineHeight: ' 63px',
    marginBottom: '25px',
    marginTop: '70px',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: '32px',
      lineHeight: ' 46px'
    }
  },
  subtitle: {
    fontSize: '24px',
    fontWeight: '400',
    maxWidth: '850px',
    textAlign: 'center',
    lineHeight: '33px',
    marginBottom: '30px'
  },
  tabStyle: {
    backgroundColor: '#FFFFFF',
    borderRadius: '60px',
    padding: '20px 30px 20px 30px',
    marginBottom: '50px',
    fontWeight: '600',
    fontSize: '22px',
    lineHeight: '26px',
    color: '#000000',
    opacity: '1',
    textTransform: 'none',
    '&:not(:first-child)': {
      marginLeft: '20px'
    },
    [theme.breakpoints.down('sm')]: {
      '&:not(:first-child)': {
        marginLeft: '10px'
      }
    }
  },
  tabs: {
    '& .MuiTabs-indicator': {
      display: 'none'
    },
    '& .MuiTab-root.Mui-selected': {
      backgroundColor: '#000000',
      color: '#FFFFFF'
    }
  },
  bookInfo: {
    backgroundColor: '#FFFFFF',
    width: '95%',
    minHeight: '245px',
    padding: '0px 80px',
    borderRadius: '10px',
    marginBottom: '30px',
    [theme.breakpoints.down('sm')]: {
      padding: '20px'
    }
  },
  bookInfoTitle: {
    display: 'flex',
    alignItems: 'center'
  },
  miniBookImage: {
    width: '30px',
    height: 'auto',
    marginRight: '10px'
  },
  eventsColumnTitles: {
    borderBottom: '1px dotted black',
    minHeight: '75px',
    paddingLeft: '35px',
    '& *': {
      fontSize: '14px'
    }
  },
  parshaList: {
    width: '95%',
    backgroundColor: '#FFFFFF',
    padding: '25px',
    borderRadius: '10px',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  }
}));

const WeeklyTorahPortionEvents = ({ children, allCategories, defaultCategory }) => {
  const classes = useStyles();
  const [category, setCategory] = useState(defaultCategory);
  const theme = useTheme();
  const isSmallerThanMd = useMediaQuery(theme.breakpoints.down('sm'));

  let filteredChildrenByCategory = children;
  const [value, setValue] = React.useState(category);

  filteredChildrenByCategory = children.filter(child => child.props.category === category);

  const categoryInfo = SECTION_CONTENT.find(c => c.title === category);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setCategory(newValue);
  };

  return (
    <Container center className={classes.root}>
      <Grid container justifyContent="center" alignItems="center">
        <Typography className={classes.title}>
          Weekly Parsha Calendar & List: <br /> Torah Portion By Date For {new Date().getFullYear()}
        </Typography>
        <Typography className={classes.subtitle}>
          Use the tool below to discover the full list of Torah Portions for the coming year, with
          links to videos and guides.
        </Typography>

        <Box maxWidth="100%">
          <Tabs
            value={value}
            onChange={handleChange}
            variant={isSmallerThanMd ? 'scrollable' : 'fullWidth'}
            className={classes.tabs}
          >
            {allCategories &&
              allCategories.map(book => (
                <Tab
                  label={book.name}
                  value={book.name}
                  className={classes.tabStyle}
                  key={book.name}
                  disableRipple
                />
              ))}
          </Tabs>
        </Box>

        <Grid
          item
          container
          justifyContent="flex-start"
          alignItems="center"
          className={classes.bookInfo}
        >
          {!isSmallerThanMd && (
            <Grid item md={2}>
              <img src={categoryInfo.image} alt="book" />
            </Grid>
          )}

          <Grid item md={10} xs={12}>
            <h2 className={classes.bookInfoTitle}>
              {isSmallerThanMd && (
                <img src={categoryInfo.image} alt="book" className={classes.miniBookImage} />
              )}
              {categoryInfo.title}
            </h2>
            <Typography>{categoryInfo.description}</Typography>
          </Grid>
        </Grid>

        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
          className={classes.parshaList}
        >
          {filteredChildrenByCategory && (
            <>
              {!isSmallerThanMd && (
                <Grid container alignItems="center" className={classes.eventsColumnTitles}>
                  <Grid item md={4}>
                    <Typography>Parsha</Typography>
                  </Grid>
                  <Grid item md={3}>
                    <Typography>Torah Verses</Typography>
                  </Grid>
                  <Grid item md={3}>
                    <Typography>Parsha Date</Typography>
                  </Grid>
                </Grid>
              )}

              {filteredChildrenByCategory.map(child => (
                <Grid item key={`event-card-key-${child.key}`}>
                  {child}
                </Grid>
              ))}
            </>
          )}
        </Grid>
      </Grid>
    </Container>
  );
};
WeeklyTorahPortionEvents.propTypes = {
  children: PropTypes.node.isRequired,
  allCategories: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired
    })
  ).isRequired,
  defaultCategory: PropTypes.string.isRequired
};

export default WeeklyTorahPortionEvents;
