import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

import unfurl from '~prismic/unfurl/weekly-torah-portion';
import { useUserContext } from '~context/UserContext/UserSessionContext';

import {
  WeeklyTorahPortionHero,
  WeeklyTorahPortionAboutAlephBeta,
  WeeklyTorahPortionAccessFromAnywhereSection,
  WeeklyTorahPortionOriginsOfParsha,
  WeeklyTorahPortionEvents,
  WeeklyTorahPortionEventItem,
  WeeklyTorahPortionParshaNavbar
} from '~modules/weekly-torah-portion';
import PageWithSlices from '~src/layout/PageWithSlices';
import FeaturedCourse from '~components/slices/featured-course';

const allCategories = [
  { name: 'Genesis', color: '#AEE8FF' },
  { name: 'Exodus', color: '#D8DEFF' },
  { name: 'Leviticus', color: '#FFE4B9' },
  { name: 'Numbers', color: '#C2ECE9' },
  { name: 'Deuteronomy', color: '#FFC39E' }
];

const WTP = ({ data }) => {
  const { session } = useUserContext();
  const { isUserStateLoading, jwtAvailable } = session;
  const { seo, slices, current, featuredPlaylist, listOfEvents } = unfurl(data);
  const { playlists } = current;

  const [{ name: defaultCategory }] = allCategories;

  const filteredSlices = useMemo(
    () => (slices || []).filter(slice => slice.slice_type !== 'featured_course'),
    [slices]
  );

  const featuredCourseSlices = useMemo(
    () => (slices || []).filter(slice => slice.slice_type === 'featured_course'),
    [slices]
  );

  return (
    <PageWithSlices
      slices={filteredSlices}
      seo={seo}
      redirectLoggedInUsersToMembers
      backgroundColor="#F6F6F6"
    >
      <WeeklyTorahPortionParshaNavbar parshaList={listOfEvents} current={current.title} />
      <WeeklyTorahPortionHero
        playlists={playlists}
        featuredPlaylist={featuredPlaylist}
        current={current}
        isLoggedIn={jwtAvailable}
        isUserStateLoading={isUserStateLoading}
      />
      {featuredCourseSlices.map(slice => (
        <FeaturedCourse key={slice.id} {...slice.primary} />
      ))}
      <WeeklyTorahPortionEvents allCategories={allCategories} defaultCategory={defaultCategory}>
        {listOfEvents &&
          listOfEvents.map(item => <WeeklyTorahPortionEventItem key={item.title} {...item} />)}
      </WeeklyTorahPortionEvents>

      <WeeklyTorahPortionAccessFromAnywhereSection />

      <WeeklyTorahPortionAboutAlephBeta />

      <WeeklyTorahPortionOriginsOfParsha />
    </PageWithSlices>
  );
};

export default withPrismicPreview(WTP);

WTP.propTypes = {
  data: PropTypes.shape().isRequired
};

export const query = graphql`
  {
    prismicWeeklyTorahPortion {
      _previewable
      data {
        current {
          document {
            ... on PrismicParshat {
              data {
                title
                start_date(formatString: "MMMM D, YYYY")
                end_date(formatString: "MMMM D, YYYY")
                verses
                slices {
                  ... on PrismicParshatDataSlicesHeroTextToc {
                    slice_type
                    primary {
                      description
                    }
                  }
                  ... on PrismicParshatDataSlicesPlaylists {
                    slice_type
                    items {
                      playlist {
                        url
                        uid
                        id
                        type
                        document {
                          ... on PrismicPlaylist {
                            uid
                            data {
                              title
                              description {
                                text
                              }
                              cover_image {
                                url
                                alt
                              }
                              author {
                                document {
                                  ... on PrismicAuthor {
                                    data {
                                      name
                                    }
                                  }
                                }
                              }
                              videos {
                                video {
                                  document {
                                    ... on PrismicVideo {
                                      data {
                                        length_in_seconds
                                      }
                                    }
                                  }
                                }
                              }
                              label_is_new
                              label_is_audio
                              label_is_premium
                              label_is_producer
                            }
                          }
                          ... on PrismicPodcastEpisode {
                            data {
                              title
                              description {
                                text
                              }
                              cover_image {
                                alt
                                url
                              }
                              cover_image_without_text {
                                alt
                                url
                              }
                              length_in_seconds
                              podcast_playlist {
                                document {
                                  ... on PrismicPodcastPlaylist {
                                    uid
                                    url
                                    data {
                                      card_thumbnail {
                                        alt
                                        url
                                      }
                                      hosts {
                                        ... on PrismicPodcastPlaylistDataHostsHost {
                                          items {
                                            host {
                                              document {
                                                ... on PrismicAuthor {
                                                  data {
                                                    name
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                          ... on PrismicPodcastPlaylist {
                            data {
                              podcast_title {
                                text
                              }
                              podcast_description {
                                text
                              }
                              cover_image_without_text {
                                url
                                alt
                              }
                              card_thumbnail {
                                url
                                alt
                              }
                              hosts {
                                ... on PrismicPodcastPlaylistDataHostsHost {
                                  items {
                                    host {
                                      document {
                                        ... on PrismicAuthor {
                                          data {
                                            name
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                              episodes {
                                ... on PrismicPodcastPlaylistDataEpisodesEpisode {
                                  primary {
                                    series_title {
                                      text
                                    }
                                  }
                                  items {
                                    podcast_episode {
                                      document {
                                        ... on PrismicPodcastEpisode {
                                          uid
                                          data {
                                            length_in_seconds
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                  ... on PrismicParshatDataSlicesResources {
                    slice_type
                    items {
                      resource {
                        url
                        document {
                          __typename
                          ... on PrismicMaterial {
                            uid
                            data {
                              title
                              description
                              required_subscription_plan
                              file {
                                url
                              }
                            }
                          }
                          ... on PrismicBlogPost {
                            uid
                            data {
                              title_text: title {
                                text
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                  ... on PrismicParshatDataSlicesFeaturedPlaylist {
                    slice_type
                    primary {
                      featured_playlist {
                        url
                        document {
                          ... on PrismicPlaylist {
                            uid
                            data {
                              title
                              description {
                                text
                                html
                              }
                              label_is_new
                              label_is_audio
                              label_is_premium
                              label_is_producer
                              cover_image {
                                alt
                                url
                              }
                              author {
                                document {
                                  ... on PrismicAuthor {
                                    data {
                                      name
                                    }
                                  }
                                }
                              }
                              videos {
                                video {
                                  document {
                                    ... on PrismicVideo {
                                      data {
                                        length_in_seconds
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        hero_featured_playlist {
          url
          document {
            ... on PrismicPlaylist {
              uid
              data {
                title
                description {
                  text
                  html
                }
                label_is_new
                label_is_audio
                label_is_premium
                label_is_producer
                cover_image {
                  alt
                  url
                }
                author {
                  document {
                    ... on PrismicAuthor {
                      data {
                        name
                      }
                    }
                  }
                }
                videos {
                  video {
                    document {
                      ... on PrismicVideo {
                        data {
                          length_in_seconds
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        body {
          ... on PrismicWeeklyTorahPortionDataBodyProducersCircleMembers {
            slice_type
            items {
              member_name
            }
          }
          ... on PrismicWeeklyTorahPortionDataBodyExpansionPanel {
            slice_type
            primary {
              panel_title
              category
            }
            items {
              panel_materials {
                url
                document {
                  ... on PrismicMaterial {
                    data {
                      title
                      material_type
                      required_subscription_plan
                      file {
                        url
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicWeeklyTorahPortionDataBodyTeamSection {
            slice_type
            primary {
              section_title {
                html
                text
              }
            }
            items {
              team_member_avatar {
                url
              }
              team_member_name {
                html
              }
              team_member_position {
                html
              }
            }
          }
          ... on PrismicWeeklyTorahPortionDataBodyJobSection {
            slice_type
            primary {
              job_section_title {
                html
                text
              }
              job_section_content {
                html
              }
              job_section_background
              job_section_button_title
              job_section_button_color
              job_section_button_link {
                url
              }
              job_section_image {
                url
                alt
              }
            }
          }
          ... on PrismicWeeklyTorahPortionDataBodyFaqSection {
            slice_type
            primary {
              section_title {
                html
                text
              }
            }
            items {
              topic_title {
                html
              }
              topic_content {
                html
              }
            }
          }
          ... on PrismicWeeklyTorahPortionDataBodyAudioFileSection {
            slice_type
            primary {
              section_title {
                html
                text
              }
            }
            audio_files: items {
              audio_file_link {
                url
              }
              title
              description {
                html
              }
              transcript {
                html
              }
            }
          }
          ... on PrismicWeeklyTorahPortionDataBodyPromotionalBox {
            slice_type
            primary {
              promotional_box_link
              promotional_box_text
              text_color
              promotional_box_background_color
              button_title
              button_background_color
              button_text_color
            }
          }
          ... on PrismicWeeklyTorahPortionDataBodyResources {
            slice_type
            primary {
              section_title_rich_text {
                html
                text
              }
            }
            items {
              resource {
                uid
                url
                document {
                  __typename
                  ... on PrismicMaterial {
                    data {
                      title
                      description
                      material_type
                      required_subscription_plan
                      file {
                        url
                      }
                    }
                  }
                  ... on PrismicParshat {
                    data {
                      title
                      slices {
                        ... on PrismicParshatDataSlicesHeroTextToc {
                          primary {
                            description
                          }
                        }
                      }
                    }
                  }
                  ... on PrismicHolidayRedesign {
                    data {
                      title
                      body {
                        ... on PrismicHolidayRedesignDataBodyHeroTextToc {
                          primary {
                            description
                          }
                        }
                      }
                    }
                  }
                  ... on PrismicAdvanced {
                    data {
                      title
                      slices {
                        ... on PrismicAdvancedDataSlicesHeroTextToc {
                          primary {
                            description
                          }
                        }
                      }
                    }
                  }
                  ... on PrismicPlaylist {
                    uid
                    data {
                      title
                      description_text: description {
                        text
                      }
                    }
                  }
                  ... on PrismicArticle {
                    data {
                      title
                      description_text: description {
                        text
                      }
                    }
                  }
                  ... on PrismicBlogPost {
                    data {
                      title_text: title {
                        text
                        html
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicWeeklyTorahPortionDataBodyAuthorTestimonials {
            slice_type
            primary {
              author {
                document {
                  ... on PrismicAuthor {
                    data {
                      name
                      role
                      avatar {
                        url
                        alt
                      }
                    }
                  }
                }
              }
              testimonial
            }
          }
          ... on PrismicWeeklyTorahPortionDataBodyAboutPageAboutUs {
            slice_type
            primary {
              section_title {
                html
                text
              }
              about_us_bg_color
            }
            sections: items {
              topic_title {
                html
              }
              topic_content {
                html
              }
              topic_image {
                url
                alt
              }
            }
          }
          ... on PrismicWeeklyTorahPortionDataBodyAboutTestimonials {
            slice_type
            primary {
              testimonials_background_color
            }
            testimonials: items {
              avatar {
                alt
                url
              }
              title
              subtitle
              name
              subscription_plan
            }
          }
          ... on PrismicWeeklyTorahPortionDataBodyAboutVideosPath {
            slice_type
            primary {
              section_title {
                html
                text
              }
              section_subtitle {
                html
              }
              section_footnote {
                html
              }
              slider_background_color
            }
            slides: items {
              title {
                html
              }
              subtitle {
                html
              }
              first_video {
                url
                document {
                  ... on PrismicPlaylist {
                    data {
                      title
                      cover_image {
                        url
                        alt
                      }
                    }
                  }
                }
              }
              second_video {
                url
                document {
                  ... on PrismicPlaylist {
                    data {
                      title
                      cover_image {
                        url
                        alt
                      }
                    }
                  }
                }
              }
              third_video {
                url
                document {
                  ... on PrismicPlaylist {
                    data {
                      title
                      cover_image {
                        url
                        alt
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicWeeklyTorahPortionDataBodyAboutTeam {
            slice_type
            primary {
              section_title {
                html
                text
              }
              section_subtitle {
                html
              }
              button_bg_color
              button_title
              button_link {
                url
              }
              button_title_color
            }
            members: items {
              avatar {
                alt
                url
              }
              name {
                html
              }
            }
          }
          ... on PrismicWeeklyTorahPortionDataBodyAboutFaq {
            slice_type
            primary {
              section_title {
                html
                text
              }
              faq_bg_color
              button_bg_color
              button_title
              button_link {
                url
              }
              button_title_color
            }
            faqSections: items {
              topic_title {
                html
              }
              topic_content {
                html
              }
            }
          }
          ... on PrismicWeeklyTorahPortionDataBodyAboutCta {
            slice_type
            primary {
              section_title {
                html
                text
              }
              section_content {
                html
              }
              section_image {
                alt
                url
              }
            }
            links: items {
              title {
                html
              }
              subtitle {
                html
              }
              to {
                url
              }
            }
          }
          ... on PrismicWeeklyTorahPortionDataBodyVideoList {
            slice_type
            primary {
              title_video_list {
                html
                text
              }
              event_date {
                html
                text
              }
            }
            items {
              playlist {
                id
                url
                document {
                  ... on PrismicPlaylist {
                    uid
                    data {
                      title
                      description {
                        text
                        html
                      }
                      label_is_new
                      label_is_audio
                      label_is_premium
                      label_is_producer
                      cover_image {
                        alt
                        url
                      }
                      author {
                        document {
                          ... on PrismicAuthor {
                            data {
                              name
                            }
                          }
                        }
                      }
                      videos {
                        video {
                          id
                          document {
                            ... on PrismicVideo {
                              data {
                                length_in_seconds
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicWeeklyTorahPortionDataBodyOneLineEmailAcquisition {
            slice_type
            primary {
              cta_text
              one_line_email_background_color
              cta_text_color
              button_title
              button_background_color
              button_text_color
            }
          }
          ... on PrismicWeeklyTorahPortionDataBodyPlaylists {
            slice_type
            primary {
              section_title_rich_text {
                html
                text
              }
            }
            items {
              playlist {
                id
                url
                document {
                  ... on PrismicPlaylist {
                    uid
                    data {
                      title
                      videos {
                        video {
                          id
                          document {
                            ... on PrismicVideo {
                              data {
                                length_in_seconds
                              }
                            }
                          }
                        }
                      }
                      author {
                        document {
                          ... on PrismicAuthor {
                            data {
                              name
                            }
                          }
                        }
                      }
                      cover_image {
                        url
                        alt
                      }
                      label_is_new
                      label_is_audio
                      label_is_premium
                      label_is_producer
                    }
                  }
                }
              }
            }
          }
          ... on PrismicWeeklyTorahPortionDataBodyTeamPageFounder {
            slice_type
            primary {
              founder_section_title {
                html
                text
              }
              founder_name {
                html
              }
              founder_position {
                html
              }
              founder_text {
                html
              }
              founder_image {
                url
              }
              founder_content_title {
                html
              }
            }
            items {
              founder_publication_title
              founder_publication {
                url
              }
            }
          }
          ... on PrismicWeeklyTorahPortionDataBodyCustomHtml {
            slice_type
            primary {
              custom_html {
                html
                text
              }
            }
          }
          ... on PrismicWeeklyTorahPortionDataBodyFaqHero {
            slice_type
            primary {
              hero_title {
                html
              }
              hero_subtitle {
                html
              }
              hero_background_image {
                url
              }
            }
          }
          ... on PrismicWeeklyTorahPortionDataBodyHeroTextToc {
            slice_type
            primary {
              title
              overline_rich_text {
                html
                text
              }
              underline_rich_text {
                html
                text
              }
              description
              navigation_menu_title
              text_color
              hero_background_color
              hero_background_image {
                url
              }
            }
          }
          ... on PrismicWeeklyTorahPortionDataBodyHolidayHeroSplitScreen {
            slice_type
            primary {
              overline_text {
                html
                text
              }
              hero_title {
                html
                text
              }
              hero_image_split_direction
              hero_subtitle {
                html
                text
              }
              hero_background_image {
                url
                alt
              }
              label_text {
                html
                text
              }
              label_text_color
              label_text_background_color
              subscription_level
              hero_featured_video {
                url
                document {
                  ... on PrismicPlaylist {
                    uid
                    data {
                      title
                      description {
                        text
                        html
                      }
                      label_is_new
                      label_is_audio
                      label_is_premium
                      label_is_producer
                      cover_image {
                        alt
                        url
                      }
                      author {
                        document {
                          ... on PrismicAuthor {
                            data {
                              name
                            }
                          }
                        }
                      }
                      videos {
                        video {
                          document {
                            ... on PrismicVideo {
                              data {
                                length_in_seconds
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicWeeklyTorahPortionDataBodyHeroTextImage {
            slice_type
            primary {
              title
              subtitle
              link {
                url
                document {
                  ... on PrismicPlaylist {
                    data {
                      label_is_new
                      label_is_audio
                      label_is_premium
                      label_is_producer
                    }
                  }
                }
              }
              link_title
              text_color
              background_color
              foreground_image {
                alt
                url
              }
              background_image {
                alt
                url
              }
            }
          }
          ... on PrismicWeeklyTorahPortionDataBodyAboutHero {
            slice_type
            primary {
              hero_text {
                html
              }
              underline_text {
                html
              }
              hero_background_image {
                url
                alt
              }
              hero_head_image {
                url
                alt
              }
              hero_featured_video {
                url
              }
            }
          }
          ... on PrismicWeeklyTorahPortionDataBodyTextBlock {
            slice_type
            primary {
              text_block_title_rich_text {
                html
                text
              }
              text_block_text_color
              text_block_link_color
              text_block_background_color
              text_block_content {
                html
              }
            }
          }
          ... on PrismicWeeklyTorahPortionDataBodyTextBlockCols {
            slice_type
            primary {
              text_block_title_rich_text {
                html
                text
              }
              text_block_text_color
              text_block_link_color
              text_block_background_color
              text_block_content_left {
                html
              }
              text_block_content_right {
                html
              }
            }
          }
          ... on PrismicWeeklyTorahPortionDataBodyFeaturedCourse {
            slice_type
            id
            primary {
              featured_course_title {
                html
              }
              featured_course_description {
                html
              }
              background_color
              text_color
              top_label
              top_label_background_color
              top_label_text_color
              cta_text
              cta_url
              featured_course_image {
                url
                alt
              }
            }
          }
        }
        seo_title
        seo_description
        seo_image {
          url
        }
        seo_keywords
      }
    }
    allPrismicBook {
      nodes {
        data {
          title
          background_color
          chapters {
            chapter {
              uid
              url
              document {
                ... on PrismicParshat {
                  data {
                    title
                    start_date(formatString: "MMMM D, YYYY")
                    end_date(formatString: "MMMM D, YYYY")
                    verses
                    slices {
                      ... on PrismicParshatDataSlicesHeroTextToc {
                        primary {
                          description
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
