import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Container } from '@material-ui/core';
import Link from '~components/Link';
import SupportingCastImage from '~images/supporting-cast.png';
import { getNavigationPageLink } from '~utils/common-site-helper';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    scrollMarginTop: '120px'
  },
  container: {
    backgroundColor: '#F8F8F8',
    borderRadius: 20,
    minHeight: '140px',
    padding: '30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '10px'
  },
  contentArea: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '40px',
    gap: '160px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      gap: 36
    }
  },
  textArea: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  imageMedia: {
    maxWidth: '250px',
    objectFit: 'contain'
  },
  title: {
    marginTop: 0,
    color: '#222222',
    textAlign: 'center',
    fontFamily: 'Inter',
    fontSize: '32px',
    fontWeight: 600,
    lineHeight: '140%',
    textTransform: 'capitalize',
    maxWidth: '426px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '28px'
    }
  },
  openPlayerBtn: {
    backgroundColor: '#1D1D1F',
    borderRadius: 22,
    color: '#FFFFFF',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 500,
    padding: '10px 28px',
    transition: 'all .2s ease-in-out',
    '&:hover': {
      backgroundColor: '#232325',
      transform: 'scale(1.03)'
    }
  }
}));

const SupportingCast = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Container className={classes.container}>
        <div id="supporting-cast" className={classes.contentArea}>
          <img
            src={SupportingCastImage}
            alt="Listen in your podcast app"
            className={classes.imageMedia}
          />
          <div className={classes.textArea}>
            <h3 className={classes.title}>want to listen in a different podcast player?</h3>
            <Link
              className={classes.openPlayerBtn}
              to={getNavigationPageLink(
                '/subscribe?goto=%2Fpodcasts%2Fbook-like-no-other%23supporting-cast',
                true
              )}
              underline="none"
            >
              Sign up to access
            </Link>
          </div>
        </div>
      </Container>
    </div>
  );
};

SupportingCast.propTypes = {};

export default SupportingCast;
