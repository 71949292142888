import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Link from '~components/Link';
import { Typography, Button, Grid } from '@material-ui/core';
import ExpandableText from './ExpandableText';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#FFFFFF',
    borderBottom: '1px dotted black',
    minHeight: '100px',
    paddingLeft: '35px',
    [theme.breakpoints.down('sm')]: {
      padding: '0px'
    }
  },
  title: {
    fontSize: '20px',
    fontWeight: '600',
    [theme.breakpoints.down('sm')]: {
      margin: '15px 0px'
    }
  },
  info: {
    fontSize: '16px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px'
    }
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-start'
    }
  },
  button: {
    border: '1px solid black',
    borderRadius: '7px',
    height: '40px',
    margin: '0px 7px',
    fontSize: '16px',
    fontFamily: 'Inter',
    textTransform: 'none',
    [theme.breakpoints.down('sm')]: {
      margin: '15px 0px',
      border: 'none'
    },
    [theme.breakpoints.down('xs')]: {
      padding: '0px'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '14px'
    }
  },
  linkStyle: {
    color: '#000000',
    padding: '0px 10px',
    '&:hover': {
      textDecoration: 'none'
    },
    [theme.breakpoints.down('sm')]: {
      color: '#158EFF'
    },
    [theme.breakpoints.down('md')]: {
      padding: '0px'
    }
  },
  summary: {
    backgroundColor: '#FFFFFF',
    borderBottom: '1px dotted #222222',
    minHeight: '100px',
    padding: '30px 220px',
    [theme.breakpoints.down('sm')]: {
      padding: '10px'
    }
  },
  parshaColumn: {
    padding: '14px 32px 14px 0px'
  }
}));

const WeeklyTorahPortionEventItem = ({ title, date, verses, description, url }) => {
  const classes = useStyles();

  return (
    <>
      <Grid container alignItems="center" className={classes.root}>
        <Grid item xs={12} md={4} className={classes.parshaColumn}>
          <Typography className={classes.title}>{title}</Typography>
          <ExpandableText text={description} />
        </Grid>
        {verses && (
          <Grid item xs={6} sm={3} md={3}>
            <Typography className={classes.info}>{verses}</Typography>
          </Grid>
        )}
        {date && (
          <Grid item xs={6} sm={3} md={3}>
            <Typography className={classes.info}>{date}</Typography>
          </Grid>
        )}

        <Grid item xs={12} sm={6} md={2} className={classes.buttonsContainer}>
          <Button className={classes.button}>
            <Link to={url} className={classes.linkStyle}>
              Learn More
            </Link>
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

WeeklyTorahPortionEventItem.propTypes = {
  title: PropTypes.string.isRequired,
  date: PropTypes.string,
  verses: PropTypes.string,
  description: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired
};

WeeklyTorahPortionEventItem.defaultProps = {
  date: null,
  verses: null
};

export default WeeklyTorahPortionEventItem;
