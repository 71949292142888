import React from 'react';
import PropTypes from 'prop-types';
import Link from '~components/Link';
import makeStyles from '@material-ui/core/styles/makeStyles';

import RichText from '~src/components/RichText';

const useStyles = makeStyles(theme => ({
  rootContainer: {
    marginTop: -120,
    backgroundColor: '#F1F1F1',
    overflow: 'auto'
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: props => props.backgroundColor,
    padding: '33px 24px 64px'
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    gap: '50px',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '1280px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    },
    [theme.breakpoints.down('xs')]: {
      gap: '20px'
    }
  },
  image: {
    maxWidth: '330px',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '200px'
    }
  },
  contentArea: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: props => (props.hasImage ? 'start' : 'center'),
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center !important'
    }
  },
  title: {
    maxWidth: '900px',
    fontSize: '36px',
    fontWeight: 600,
    marginBlockStart: 0,
    marginBlockEnd: 0,
    color: props => props.textColor,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '28px'
    }
  },
  content: {
    maxWidth: '870px',
    '& > *': {
      fontSize: '24px',
      marginBlockStart: 0,
      marginBlockEnd: 0,
      color: props => props.textColor,
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center'
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '20px'
      }
    }
  },
  ctaButton: {
    color: '#FFFFFF',
    padding: '17px 46px',
    backgroundColor: '#278AE7',
    borderRadius: '8px',
    fontFamily: 'Inter',
    fontSize: '21px',
    fontWeight: 600,
    cursor: 'pointer',
    width: 'fit-content',
    [theme.breakpoints.down('xs')]: {
      fontSize: '18px'
    }
  }
}));

const PodcastUneditedContent = ({
  title,
  content,
  backgroundColor,
  textColor,
  ctaText,
  playlistUrl,
  thumbnail
}) => {
  const classes = useStyles({ backgroundColor, textColor, hasImage: !!thumbnail });

  return (
    <div className={classes.rootContainer}>
      <div className={classes.root}>
        <div className={classes.container}>
          {thumbnail && (
            <img
              src={thumbnail.url}
              className={classes.image}
              alt={thumbnail.alt || 'Unedited Content Thumbnail'}
            />
          )}

          <div className={classes.contentArea}>
            <h4 className={classes.title}>{title}</h4>
            <RichText html={content} externalClassName={classes.content} />
            <Link to={playlistUrl} className={classes.ctaButton} underline="none">
              {ctaText}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PodcastUneditedContent;

PodcastUneditedContent.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  playlistUrl: PropTypes.string.isRequired,
  ctaText: PropTypes.string,
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string,
  thumbnail: PropTypes.shape({
    url: PropTypes.string,
    alt: PropTypes.string
  })
};

PodcastUneditedContent.defaultProps = {
  ctaText: 'Unedited Playlist',
  backgroundColor: '#C4D9E5',
  textColor: '#000000',
  thumbnail: null
};
