import { getRequiredPlanId } from '~utils/required-subscription-plan';
// import { boolFlagFromString } from '~utils/playlist-helpers';
import { get } from '../utils/get';

export default prismicData => ({
  prismicId: get(prismicData, 'prismicPodcastPlaylist.prismicId', null),
  uid: get(prismicData, 'prismicPodcastPlaylist.uid', null),
  requiredSubscriptionPlan: getRequiredPlanId(
    get(prismicData, 'prismicPodcastPlaylist.data.required_subscription_plan', '')
  ),
  coverColor: get(prismicData, 'prismicPodcastPlaylist.data.cover_color', null),
  playButtonColor: get(prismicData, 'prismicPodcastPlaylist.data.play_button_color', ''),
  playButtonTextColor: get(
    prismicData,
    'prismicPodcastPlaylist.data.play_button_text_color',
    '#FFFFFF'
  ),
  podcastThumbnail: get(prismicData, 'prismicPodcastPlaylist.data.podcast_thumbnail', null),
  podcastTitle: get(prismicData, 'prismicPodcastPlaylist.data.podcast_title.text', null),
  podcastSponsorship: get(
    prismicData,
    'prismicPodcastPlaylist.data.podcast_sponsorship.html',
    null
  ),
  podcastDescription: get(
    prismicData,
    'prismicPodcastPlaylist.data.podcast_description.html',
    null
  ),
  podcastSubtitle: get(prismicData, 'prismicPodcastPlaylist.data.podcast_subtitle.html', null),
  rssFeedInstructions: get(
    prismicData,
    'prismicPodcastPlaylist.data.rss_feed_instruction_video',
    ''
  ),
  providers: get(prismicData, 'prismicPodcastPlaylist.data.providers[0].items', []).map(
    provider => ({
      id: get(provider, 'provider_id', ''),
      url: get(provider, 'url.url', null)
    })
  ),
  hosts: get(prismicData, 'prismicPodcastPlaylist.data.hosts[0].items', []).map(({ host }) => ({
    name: get(host, 'document.data.name', ''),
    role: get(host, 'document.data.role', ''),
    avatar: get(host, 'document.data.avatar', {})
  })),
  podcastSeries: get(prismicData, 'prismicPodcastPlaylist.data.episodes', []).map(series => ({
    uid: get(series, 'primary.series_uid', ''),
    title: get(series, 'primary.series_title.html', ''),
    heroBackground: get(series, 'primary.hero_background', null),
    playButtonColor: get(series, 'primary.episode_play_button_color', ''),
    playButtonTextColor: get(series, 'primary.episode_play_button_text_color', ''),
    listenOnSpotify: {
      url: get(series, 'primary.spotify_listen_url', ''),
      id: 'Spotify'
    },
    listenOnApple: {
      url: get(series, 'primary.apple_listen_url', ''),
      id: 'Apple'
    },
    listenOnGoogle: {
      url: get(series, 'primary.google_listen_url', ''),
      id: 'Google'
    },
    listenOnRSSFeed: {
      url: get(series, 'primary.rss_feed_url', ''),
      id: 'RSS Feed'
    },
    subscriberRssFeed: {
      url: get(series, 'primary.subscriber_rss_feed_url', '')
    },
    enableSupportingCast: get(series, 'primary.enable_supporting_cast', false),
    episodes: get(series, 'items', []).map(({ podcast_episode: episode }) => ({
      prismicId: get(episode, 'document.prismicId', ''),
      slug: get(episode, 'slug', ''),
      uid: get(episode, 'uid', ''),
      color: get(episode, 'document.data.color', ''),
      createdAt: new Date(get(episode, 'document.data.created_at', '')) || null,
      episode: get(episode, 'document.data.episode', null),
      season: get(episode, 'document.data.season', null),
      part: get(episode, 'document.data.part', null),
      title: get(episode, 'document.data.title', ''),
      duration: get(episode, 'document.data.length_in_seconds', ''),
      transcript: get(episode, 'document.data.transcript.text', ''),
      audioUrl: get(episode, 'document.data.audio_url.url', null),
      wistiaStreamUrl: get(episode, 'document.data.wistia_stream_url.url', null),
      spotifyUrl: get(episode, 'document.data.spotify_url.url', null),
      playerEmbedCode: get(episode, 'document.data.player_embed_code', ''),
      isSubscriberEpisode: get(episode, 'document.data.is_subscriber_episode', false),
      coverImage: get(episode, 'document.data.cover_image', ''),
      description: get(episode, 'document.data.description.text', '')
    }))
  })),
  previousSeasons: get(prismicData, 'prismicPodcastPlaylist.data.previous_seasons', []).map(
    season => ({
      ctaText: get(season, 'cta_text', ''),
      url: get(season, 'previous_playlist.document.url', ''),
      uid: get(season, 'previous_playlist.document.uid', ''),
      slug: get(season, 'previous_playlist.slug', '')
    })
  ),
  uneditedContent: {
    title: get(prismicData, 'prismicPodcastPlaylist.data.unedited_content_title', ''),
    content: get(prismicData, 'prismicPodcastPlaylist.data.unedited_content_description.html', ''),
    playlistUrl: get(prismicData, 'prismicPodcastPlaylist.data.unedited_content_playlist.url', ''),
    ctaText: get(prismicData, 'prismicPodcastPlaylist.data.unedited_content_cta_text', ''),
    backgroundColor: get(
      prismicData,
      'prismicPodcastPlaylist.data.unedited_content_background_color',
      ''
    ),
    textColor: get(prismicData, 'prismicPodcastPlaylist.data.unedited_content_text_color', ''),
    thumbnail: get(prismicData, 'prismicPodcastPlaylist.data.unedited_content_thumbnail', null)
  },
  seo: {
    seo_title: get(prismicData, 'prismicPodcastPlaylist.data.seo_title', ''),
    seo_description: get(prismicData, 'prismicPodcastPlaylist.data.seo_description', ''),
    seo_keywords: get(prismicData, 'prismicPodcastPlaylist.data.seo_keywords', ''),
    seo_image:
      get(prismicData, 'prismicPodcastPlaylist.data.seo_image.url', '') ||
      get(prismicData, 'prismicPodcastPlaylist.data.podcast_thumbnail.url', ''),
    disable_page_title_suffix: true
  }
});
