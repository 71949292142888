import React, { useState } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import WhitePlayButton from '~images/buttons/play_btn_white.svg';
import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import Link from '~components/Link';
import DesktopDescription from './DesktopDescription';
import MobileDescription from './MobileDescription';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    cursor: 'pointer',
    position: 'relative'
  },
  media: {
    aspectRatio: '16 / 9',
    borderRadius: 13,
    minHeight: '100px',
    border: '1px solid #FFFFFF',
    [theme.breakpoints.down('sm')]: {
      border: 'none',
      borderRadius: '12px 12px 0px 0px'
    }
  },
  playBtn: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 20,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: 999,
    [theme.breakpoints.down('sm')]: {
      width: 80,
      height: 80
    },
    [theme.breakpoints.down('xs')]: {
      width: 50,
      height: 50
    }
  }
}));

const VideoPlayCard = ({
  url,
  title,
  description,
  image,
  author,
  videoCount,
  isAudio,
  duration
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [isHover, setIsHover] = useState(false);

  const onCardHover = () => setIsHover(true);
  const onCardHoverOut = () => setIsHover(false);

  const playButtonImage = WhitePlayButton;

  return (
    <Link to={url} underline="none">
      <div className={classes.root} onMouseEnter={onCardHover} onMouseLeave={onCardHoverOut}>
        {isHover && !isSmallerScreen ? (
          <DesktopDescription
            title={title}
            description={description}
            author={author}
            videoCount={videoCount}
            isAudio={isAudio}
            duration={duration}
          />
        ) : (
          <img className={classes.playBtn} src={playButtonImage} alt="play button" />
        )}
        <img className={classes.media} src={image?.url} alt={image?.alt || title} />
      </div>
      {isSmallerScreen && (
        <MobileDescription
          title={title}
          description={description}
          author={author}
          videoCount={videoCount}
          isAudio={isAudio}
          duration={duration}
        />
      )}
    </Link>
  );
};

VideoPlayCard.propTypes = {
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  image: PropTypes.shape({
    url: PropTypes.string,
    alt: PropTypes.string
  }).isRequired,
  author: PropTypes.string,
  videoCount: PropTypes.number.isRequired,
  isAudio: PropTypes.bool,
  duration: PropTypes.string
};

VideoPlayCard.defaultProps = {
  description: '',
  author: '',
  isAudio: false,
  duration: ''
};

export default VideoPlayCard;
