import React, { useState, useRef, useEffect } from 'react';
import { Typography, IconButton, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    alignItems: 'flex-start', // Align text and button in the same row
    flexWrap: 'nowrap',
    overflow: 'hidden',
    position: 'relative'
  },
  textWrapper: {
    flex: 1, // Allow text to take available space
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2, // Limit to 2 lines
    transition: 'all 0.3s ease',
    textOverflow: 'ellipsis'
  },
  expanded: {
    WebkitLineClamp: 'unset' // Show full text
  },
  button: {
    flexShrink: 0, // Prevent button from resizing
    padding: 0,
    marginLeft: -10,
    alignSelf: 'end'
  }
}));

const ExpandableText = ({ text }) => {
  const classes = useStyles();
  const [isExpanded, setIsExpanded] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const textRef = useRef(null);

  useEffect(() => {
    if (textRef.current) {
      setIsOverflowing(textRef.current.scrollHeight > textRef.current.clientHeight);
    }
  }, [text]);

  return (
    <div className={classes.container}>
      <Typography
        ref={textRef}
        variant="body2"
        color="textSecondary"
        className={`${classes.textWrapper} ${isExpanded ? classes.expanded : ''}`}
      >
        {text}
      </Typography>
      {isOverflowing && (
        <IconButton
          onClick={() => setIsExpanded(!isExpanded)}
          className={classes.button}
          size="small"
        >
          {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      )}
    </div>
  );
};

ExpandableText.propTypes = {
  text: PropTypes.string.isRequired
};

export default ExpandableText;
